import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";

const DeclarationModal = ({ show, setShow, declarationData, setFieldValue, fieldName }) => {
    const [canAccept, setCanAccept] = useState(false);

    const setModalRef = (target) => {
      if (target) {
        const scrollTopTotalHegight = target.scrollHeight - target.offsetHeight -100;
        if (target.scrollTop >= scrollTopTotalHegight || scrollTopTotalHegight <= 0) {
          setCanAccept(true);
        } else {
          setCanAccept(false);
        }
      }
    };

    const handleClose = () => {
      setShow(false);
      setCanAccept(false);
    };
  
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <>
          <Modal.Header>
            <Modal.Title className="cover_letter_modal_view">
              <h2 className="mb-0">{ declarationData.label }</h2>
              <button type="button" onClick={handleClose}>
                <i
                  className="fas fa-times text-danger"
                  style={{ fontSize: "26px" }}
                ></i>
              </button>
            </Modal.Title>
          </Modal.Header>
  
          <Modal.Body
            ref={setModalRef}
            onScroll={(e) => {
              if (!canAccept) {
                let targetModelBody = e.target;
                const scrollTopTotalHegight =
                  targetModelBody.scrollHeight -
                  targetModelBody.offsetHeight -
                  100;
                if (targetModelBody.scrollTop >= scrollTopTotalHegight) {
                  setCanAccept(true);
                }
              }
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <div
                  className="col-md-12 mb-3"
                  dangerouslySetInnerHTML={{ __html: declarationData?.declaration }}
                ></div>
              </div>
            </div>
          </Modal.Body>
  
          <Modal.Footer>
            <div
              className="row col-md-12"
              style={{ justifyContent: "end" }}
            >
              {/* <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleClose();
                  setFieldValue(fieldName, 0);
                }}
              >
                Decline
              </button> */}
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  handleClose();
                  setFieldValue(fieldName, 1);
                }}
                disabled={!canAccept}
              >
                Accept
              </button>
            </div>
          </Modal.Footer>
        </>
      </Modal>
    );
};

export default DeclarationModal;