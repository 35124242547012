import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const HRMSSunEditor = ({
  placeholder = "Please type here...",
  setEditorHtml,
  editorHtml,
  onChange,
  disabled = false,
  height = "250px",
  isHeight = true,
}) => {
  const options = {
    mode: "classic",
    rtl: false,
    katex: "window.katex",
    height: isHeight ? height : 300,
    imageGalleryUrl:
      "https://etyswjpn79.execute-api.ap-northeast-1.amazonaws.com/suneditor-demo",
    videoFileInput: false,
    tabDisable: false,
    font: [
      "Arial",
      "Comic Sans MS",
      "Courier New",
      "Impact",
      "Georgia",
      "Tahoma",
      "Trebuchet MS",
      "Verdana",
      "Times New Roman",
      "Calibri",
    ],
    buttonList: [
      [
        "undo",
        "redo",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "table",
        "link",
        "image",
        "video",
        "audio",
        "math",
        // "imageGallery",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print",
        "save",
      ],
    ],
    "lang(In nodejs)": "en",
  };

  return (
    <SunEditor
      onChange={onChange}
      setContents={editorHtml}
      placeholder={placeholder}
      disabled={disabled}
      height={isHeight ? height : ""}
      setOptions={options}
    />
  );
};

export default HRMSSunEditor;
