import React, { useEffect } from "react";
import { CoverSection, Recommended, Departments, AvailableJobs } from "../../components/landing";
import { Header, Footer } from "../../../layout";
import { HeaderMobile } from "../../../_metronic/layout/components/header-mobile/HeaderMobile"; 
import { useDispatch, useSelector } from 'react-redux';   

export default function HomePage() {
    
    // const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    // useEffect(() => {
    //     dispatch(fetchjobsDepartmentData());
    // }, [dispatch]);

    // console.log("jobsDepartmentList", {jobsDepartmentList});

 
    return (
        <>


            <Header />
            <HeaderMobile />

            <CoverSection />
            {
                user?.data?.applicant_id && (
                    <Recommended />
                )
            }
            <Departments />
            <AvailableJobs />

            <Footer />
        </>
    )
} 