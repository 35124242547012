import { swalError } from "./swal";
import API from "./devApi"

export const downloadResume = async (applicant_id, setLoading) => {
    setLoading && setLoading(true);
    try {
        const {data} = await API.post('/hrms_job_application/generate-shortlisted-cv-bulk', [applicant_id], {
            params: {
                type: "pdf",
                request_from: "cp"
            }
        });

        const url = `${process.env.REACT_APP_API_URL}/${data?.data?.file}`;
        const linkElement = document.createElement('a');
        linkElement.href = url;
        linkElement.target = "_blank";

        linkElement.click();
    } catch (error) {
        swalError(error?.response?.data?.message || "Something went wrong");
        console.log(error)
    } finally {
        setLoading && setLoading(false);
    }
}