import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as jobAction from "../app/_redux/jobActions"

export function Footer() {
    const dispatch = useDispatch();
    const { systemInfo } = useSelector((state) => state.job);
    const [socialMedia, setSocialMedia] = useState([])

    useEffect(() => {
        dispatch(jobAction.fetchALLSystemInfo());
    }, [dispatch]);

    useEffect(() => {
        if (systemInfo && systemInfo?.social_media) {
            const data = JSON.parse(systemInfo?.social_media);
            setSocialMedia(JSON.parse(data))
        }
    }, [systemInfo]);

    return (
        <>
            <div className="footer-section">
                <div className="container-fluid">
                    <div className="footer-wrap">
                        <div className="address-div">
                            <ul>
                                {/* <li>{systemInfo?.company_id == 1 ? "BRAC Centre:" : systemInfo?.company_id == 2 ? "BRAC IGD:" : systemInfo?.company_id == 3 ? "BRAC IED:" : "BRAC JPGSPH:"}  {systemInfo?.address}</li> */}
                                <li>{systemInfo?.address}</li>
                                <li>Tel: {systemInfo?.phone_no}</li>
                                <li>E-mail: {systemInfo?.email}</li>
                            </ul>
                        </div>
{/* 
                        <div className="footer-menu-list">
                            <ul>
                                <li>RESEARCH</li>
                                <li>ADVOCACY</li>
                                <li>INNOVATION</li>
                            </ul>
                        </div> */}
                        <div className="footer-social-menu">
                            <p>Follow us at</p>
                            <ul>
                                {/* {JSON.stringify(socialMedia)} */}
                                {socialMedia && socialMedia?.map((item, i) => {
                                    return (
                                        <>
                                            <li className="pl-0">
                                                <a href={item?.url ?? item?.url} target="_blank" rel="noreferrer">
                                                    <i className={item?.icon}></i>
                                                </a>
                                            </li>
                                        </>
                                    )
                                })}
                                {/* {systemInfo?.facebook_link && (
                                    <li className="pl-0">
                                        <a href={systemInfo?.facebook_link ?? systemInfo?.facebook_link} target="_blank" rel="noreferrer">
                                            <i className="fab fa-facebook-square"></i>
                                        </a>
                                    </li>
                                )
                                }
                                {systemInfo?.twitter_link && (
                                    <li>
                                        <a href={systemInfo.twitter_link ?? systemInfo.twitter_link} target="_blank" rel="noreferrer">
                                            <i className="fab fa-twitter-square"></i>
                                        </a>
                                    </li>
                                )
                                }
                                {systemInfo?.youtube_link && (
                                    <li>
                                        <a href={systemInfo.youtube_link ?? systemInfo.youtube_link} target="_blank" rel="noreferrer">
                                            <i className="fab fa-youtube"></i>
                                        </a>
                                    </li>
                                )
                                }
                                {systemInfo?.linked_in_link && (
                                    <li>
                                        <a href={systemInfo.linked_in_link ?? systemInfo.linked_in_link} target="_blank" rel="noreferrer">
                                            <i className="fab fa-linkedin"></i>
                                        </a>
                                    </li>
                                )}
                                {systemInfo?.instagram_link && (
                                    <li>
                                        <a href={systemInfo.instagram_link ?? systemInfo.instagram_link} target="_blank" rel="noreferrer">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                )} */}

                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
} 